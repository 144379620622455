/*
 * CUSTOM JAVASCRIPT
 *
 * All of your custom javascript can be placed below.
 * Do NOT forgot to add comments above the different functions or functionalities that need explaining.
 * 
 */

/**
 * GMAPS
 * 
 * Author: Mannus van der Meer <mannus@click.nl>
 */
var gmaps_location = function ( locations ) {
    var self = this;
    var map;
    // Default Worldwide
    var map_position = new google.maps.LatLng(37.5229468, 7.9704928);
    var map_default_zoom = 2;

    // Define marker
    // var marker_img = '/assets/images/frontend/icn_gmaps_marker.png';

    // Load specific office map
    self.loadMap = function() {

        google.maps.event.addDomListener(window, 'load', self.initializeMap);

    }

    // Initialize office map
    self.initializeMap = function() {
        
        var latlng_center = new google.maps.LatLng( '52.5246914','4.9797787' );
        var latlng_marker = new google.maps.LatLng( locations[0],locations[1] );

        // Define the options of the map
        var map_options = {
            center: latlng_center,
            scrollwheel: false,
            zoom: 15,
            panControl: false,
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            styles: [{"featureType":"all","elementType":"labels.text.fill"}]
        };

        // Load map in element
        map = new google.maps.Map( document.getElementById( 'map-canvas' ), map_options );

        //Set marker position
        var map_marker = new google.maps.Marker({
            position: latlng_marker,
            map: map,
            // icon: marker_img,
            url: locations[0].url
        });

        // Keep the map centered
        $( window ).on( 'resize orientationchange', function() {
            map.panTo( latlng_marker );
        });

        google.maps.event.addListener( map_marker, 'click', function() {
            window.open(this.url, '_blank');
        });
    }
}