/**
 * Slick slider
 * 
 * Author: Wouter Dolk
 */
 var slick_slider = function(){
    var self = this;
    var $slider = $('.js-slick-wrapper');
    
    self.init = function(){
        // the settings for the slick slider
        $slider.slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev-button"><span class="icon-arrow-65"></span></button>',
            nextArrow: '<button type="button" class="slick-next-button"><span class="icon-arrow-66"></span></button>',
            appendArrows: $('.js-slick-arrows'),
            // the settings for the breakpoint
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                },
            ]
        });
    }
}